<template>
  <div id="submission">
    <top :name="text" />
    <div class="dizhi">
      <div class="dizhi_div" @click="select">
        <div class="middleInfo">
          <div class="shouhuoren">
            <div class="picker">{{ pick_name }}</div>
            <div class="phone">{{ pick_phone }}</div>
          </div>
          <div class="shouhuodizhi">
            <span class="detailAddress">{{ pick_dizhi }}</span>
          </div>
        </div>
        <div class="rightIcon">
          <img
            style="width: 0.22rem; height: 0.22rem"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAA3NCSVQICAjb4U/gAAAAKlBMVEX///+3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7cn0wXWAAAADnRSTlMAESIzRHeImaq7zN3u//RDEIsAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMjcvMTdPXTLGAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAAGdJREFUGJVjYGBgEDVgQIC1V5A4d+8gScXePYzgsN29o4Dg1d7dhOCw370tgOD13l2I4HDevYWQYpx7dyJCSuruDQSHce3dAgTP9u4F7DLIelBMQ7EH2QUobkN2NYp/UHyKEgbw0AEAumwr1Mwo4uIAAAAASUVORK5CYII="
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="shopping">
      <div class="a">
        <img class="imghhh" :src="bayget.imgurl" alt="" />
      </div>
      <div style="position: relative">
        <p
          class="bn"
          style="
            font-size: 0.3rem;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          "
        >
          {{ bayget.tradename }}
        </p>
        <p class="bn" style="font-size: 0.2rem; color: gray">
          {{ bayget.specifications }}
        </p>
        <div class="zuoyou">
          <p style="color: red">￥{{ bayget.skuprice }} + 0积分</p>
          <p>×{{ bayget.stock_num }}</p>
        </div>
      </div>
    </div>
    <van-coupon-cell
      :coupons="coupons"
      :chosen-coupon="chosenCoupon"
      @click="showList = true"
    />
    <!-- 买家留言 -->
    <div class="fuyan">
      <p class="ag">买家留言</p>
      <input type="text" v-model="fuyan" placeholder="是否有特殊备注(选填)" />
    </div>
    <!-- 商品价格信息 -->
    <div class="am-list-item">
      <div class="line">
        <p>商品金额</p>
        <p style="color: red">￥{{ bayget.price }}</p>
      </div>
      <div class="line" v-if="bayget.Testpassed != 'Testpassed'">
        <p>所需积分</p>
        <p style="color: red">0</p>
      </div>
      <div class="line">
        <p>立减</p>
        <p style="color: red">-￥0</p>
      </div>
      <div class="line">
        <p>优惠券</p>
        <p style="color: red">-￥0</p>
      </div>
      <div class="line">
        <p>运费</p>
        <p style="color: red">包邮</p>
      </div>
    </div>
    <!-- 底部 -->
    <van-submit-bar
      :price="(bayget.price * 100)"
      button-text="提交订单"
      suffix-label="+ 0积分"
      @submit="onSubmit"
      v-if="bayget.Testpassed != 'Testpassed'"
    />
    <!-- 拼团购订单提交 -->
    <van-submit-bar
      :price="bayget.price * 100"
      button-text="立即拼团"
      @submit="onbuy"
      v-if="bayget.Testpassed == 'Testpassed'"
    />
    <!-- 优惠券列表 -->
    <!-- <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <van-coupon-list
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="disabledCoupons"
        @change="onChange"
        @exchange="onExchange"
      />
    </van-popup> -->
  </div>
</template>

<script>
import top from "../components/filltop.vue";
import wxx from "weixin-jsapi";
import { Dialog } from 'vant';
// 优惠券 列表
// const coupon = {
//   id: 0, // 优惠券id
//   discount: 0, // 折扣券 折值 整数 为0不显示折
//   denominations: 2000, // 优惠券金额 单位分
//   originCondition: 5000, // 满减规则金额 单位分 为0显示无门槛
//   value: 12, // 折扣券优惠金额，单位分
//   name: "新人大礼包", // 优惠券名称
//   description: "喜欢你就用", // 描述信息
//   reason: "订单未满200元", // 不可用原因，优惠券不可用时展示
//   startAt: parseInt(1556337595530 / 1000), // 卡有效开始时间 (时间戳, 单位秒)
//   endAt: parseInt(1559337595530 / 1000), // 卡失效日期 (时间戳, 单位秒)
// };
export default {
  data() {
    return {
      text: "提交订单",
      chosenCoupon: -1,
      coupons: [],
      // disabledCoupons: [coupon],
      showList: false,
      fuyan: "",
      bayget: {},
      pick_data: {},
      pick_name: "请选择配送地址",
      pick_phone: "",
      pick_dizhi: "",
      // 支付调试参数
      // text_zhifu: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(to,from,'from')
      if (
        from.name == "details" ||
        from.name == "addresslist" ||
        from.name == "collagedot"
      ) {
        console.log(to, from);
        if (vm.$route.params.bayget != null) {
          vm.bayget = JSON.parse(vm.$route.params.bayget);
        }
        if (sessionStorage.getItem("dizhi") != null) {
          vm.pick_data = JSON.parse(sessionStorage.getItem("dizhi"));
          vm.pick_name = vm.pick_data.name;
          vm.pick_phone = vm.pick_data.tel;
          vm.pick_dizhi =
            vm.pick_data.province +
            vm.pick_data.city +
            vm.pick_data.area +
            vm.pick_data.address;
        }
        
      }
      
    });
  },
  methods: {
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange(code) {},
    onSubmit() {
      var that = this;
      // 订单提交
      JSON.parse(sessionStorage.getItem("dizhi")) == null
        ? this.$toast.fail("请选择配送地址")
        : this.$https(
            "mock/payment",
            "id=" +
              sessionStorage.getItem("user_id") +
              "&phone=" +
              sessionStorage.getItem("user_phone") +
              "&stock_num=" +
              sessionStorage.getItem("stock_num") +
              "&commodity_id=" +
              sessionStorage.getItem("commodity_id") +
              "&parameter=" +
              sessionStorage.getItem("parameter") +
              "&attrid=" +
              sessionStorage.getItem("attrid") +
              "&message=" +
              this.fuyan +
              "&wxid=" +
              sessionStorage.getItem("wx_openid") +
              "&address=" +
              JSON.parse(sessionStorage.getItem("dizhi")).id,
            "post"
          )
            .then((res) => {
              if (res.data.code == "000000") {
                // 如果订单创建成功 返回订单号码给下个支付请求
                return res.data.data;
              } else {
                // 订单创建失败提示
                this.$toast.fail(res.data.msg);
              }
            })
            .then((rs) => {
              this.$https(
                "mall/payorder",
                "id=" +
                  sessionStorage.getItem("user_id") +
                  "&phone=" +
                  sessionStorage.getItem("user_phone") +
                  "&wxid=" +
                  sessionStorage.getItem("wx_openid") +
                  "&source=" +
                  "1" +
                  "&url=" +
                  encodeURIComponent(window.location.href.split("#")[0]) +
                  "&orderid=" +
                  rs,
                "post"
              ).then((success_res) => {
                console.log("微信下单返回", success_res);
                wxx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: "wx0343cf05decc1643", // 必填，公众号的唯一标识
                  timestamp: success_res.data.data.timeStamp, // 必填，生成签名的时间戳
                  nonceStr: success_res.data.data.nonceStr, // 必填，生成签名的随机串
                  signature: success_res.data.data.paySign, // 必填，签名
                  jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
                }),
                  wxx.ready(() => {
                    // alert("成功")
                    wxx.chooseWXPay({
                      timestamp: success_res.data.data.timeStamp,
                      nonceStr: success_res.data.data.nonceStr,
                      package: success_res.data.data.package,
                      signType: "RSA",
                      paySign: success_res.data.data.paySign,
                      // 用户支付成功和取消支付都跳转到订单详情页面
                      success: function () {
                        // 用户支付成功
                        that.xiangqing(rs);
                      },
                      fail: function () {
                          that.onbayWXnavto(success_res)
                          Dialog.alert({
                            title: '查询支付状态',
                          }).then(() => {
                            that.xiangqing(rs) //传递订单编号
                          });
                      },
                      cancel: function (err) {
                        // 用户取消支付
                        that.xiangqing(rs);
                      },
                    });
                  }),
                  wxx.error((res) => {
                    alert("进行wx.error失败");
                  });
              });
            });
    },
    // 订单详情
    xiangqing(id) {
      // alert("支付成功")
      this.$https(
        "mall/orderDetails",
        "phone=" +
          sessionStorage.getItem("user_phone") +
          "&orderid=" +
          id +
          "&id=" +
          sessionStorage.getItem("user_id"),
        "post"
      ).then((res) => {
        // console.log(res)
        if (res.data.code == "000000") {
          console.log(JSON.stringify(res.data.data));
          this.$router.replace({
            name: "morenext",
            params: {
              success_data: JSON.stringify(res.data.data),
            },
          });
        }
      });
    },
    // 选择地址
    select() {
      this.$router.push({
        name: "addresslist",
      });
      sessionStorage.setItem("id", "900");
    },
    // 拼团购订单提交
    onbuy() {
      var that = this;

      // 订单提交
      JSON.parse(sessionStorage.getItem("dizhi")) == null
        ? this.$toast.fail("请选择配送地址")
        : this.$https(
            "collage/getcollage",
            "id=" +
              sessionStorage.getItem("user_id") +
              "&phone=" +
              sessionStorage.getItem("user_phone") +
              "&stock_num=" +
              1 +
              "&commodity_id=" +
              sessionStorage.getItem("commodity_id") +
              //商品id
              "&queryId=" +
              sessionStorage.getItem("shangpid") +
              "&attrid=" +
              sessionStorage.getItem("attrid") +
              "&message=" +
              this.fuyan +
              "&wxid=" +
              sessionStorage.getItem("wx_openid") +
              "&address=" +
              JSON.parse(sessionStorage.getItem("dizhi")).id,
            "post"
          )
            .then((res) => {
              if (res.data.code == "000000") {
                // 如果订单创建成功 返回订单号码给下个支付请求
                return res.data.data;
              } else {
                // 订单创建失败提示
                this.$toast.fail(res.data.msg);
                return false;
              }
            })
            .then((rs) => {
              var order_true = rs;
              this.$https(
                "collage/collagewxpay",
                "id=" +
                  sessionStorage.getItem("user_id") +
                  "&phone=" +
                  sessionStorage.getItem("user_phone") +
                  "&wxid=" +
                  sessionStorage.getItem("wx_openid") +
                  "&commodity_id=" +
                  sessionStorage.getItem("commodity_id") +
                  "&attrid=" +
                  sessionStorage.getItem("attrid") +
                  "&source=" +
                  "1" +
                  "&url=" +
                  encodeURIComponent(window.location.href.split("#")[0]) +
                  "&orderid=" +
                  rs,
                "post"
              ).then((success_res) => {
                console.log("微信下单返回", success_res);
                wxx.config({
                  debug: false,
                  appId: "wx0343cf05decc1643",
                  timestamp: success_res.data.data.timeStamp,
                  nonceStr: success_res.data.data.nonceStr,
                  signature: success_res.data.data.paySign,
                  jsApiList: ["chooseWXPay"],
                }),
                  wxx.ready(() => {
                    // alert("成功")
                    wxx.chooseWXPay({
                      timestamp: success_res.data.data.timeStamp,
                      nonceStr: success_res.data.data.nonceStr,
                      package: success_res.data.data.package,
                      signType: "RSA",
                      paySign: success_res.data.data.paySign,
                      // 用户支付成功和取消支付都跳转到订单详情页面
                      success: function () {
                        // 用户支付成功
                        that.collagedebay(order_true) //传递订单编号
                      },
                      fail: function () {
                          that.onbayWXnavto(success_res)
                          Dialog.alert({
                            title: '查询支付状态',
                          }).then(() => {
                            that.collagedebay(order_true) //传递订单编号
                          });
                      },
                      cancel: function (err) {
                        // 用户取消支付
                        that.deletebey(order_true);
                        alert("取消支付");
                        // that.xiangqing(rs);
                      },
                    });
                  }),
                  wxx.error((res) => {
                    alert("进行wx.error失败");
                  });
              });
            });
    },
    deletebey(id) {
      this.$https(
        "collage/baygetcollage",
        "id=" +sessionStorage.getItem("user_id") +
          "&phone=" +sessionStorage.getItem("user_phone") +
          "&order_collage=" +
          sessionStorage.getItem("commodity_id") +
          "&collageset=" +
          id,
        "post"
      ).then((ressmeg) => {});
    },
    // 页面拉起微信支付失败跳转小程序拉起微信支付
    onbayWXnavto(succ){
        // 用户支付失败 再跳转小程序进行支付 在小程序页面进行订单删除操作
        // 
        // npm方式注入sdk失败  操作dom创建标签
        var wxjs = document.createElement("script");
        wxjs.type = "text/javascript";
        wxjs.src =
          "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
        document.body.appendChild(wxjs);
        // 异步调用sdk方法
        wxjs.onload = () => {
          // 伪定义 wx
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateTo({
            url:
              "/webH5/wxpay/wxpay?success_res=" +
              encodeURIComponent(JSON.stringify(succ)),
          });
        };
    },
    // 拼团购订单详情查询
    collagedebay(order_true){
      this.$https(
        'order/ressover',
        "id=" +sessionStorage.getItem("user_id") +
        "&phone=" +sessionStorage.getItem("user_phone") +
        "&commodity_id="+sessionStorage.getItem("commodity_id")+
        "&order_number="+order_true,
        'post'
      ).then(res=>{
        if (res.data.code == "000000") {
          this.$router.replace({
            name: "collagetobayto",
            params: {
              success_data: JSON.stringify(res.data.data),
            },
          });
        }else{
          this.deletebey(order_true);
          this.$toast.fail('请尽快完成支付')
        }
      })
    }
  },
  components: {
    top,
  },
  created() {
    // 获取传输过来的商品信息
  },
  mounted() {
    this.bayget = JSON.parse(this.$route.params.bayget);
    console.log(1111)
  },
};
</script>

<style scoped>
.dizhi {
  width: 100%;
  height: auto;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#028acc),
    to(#b9ddee)
  );
  background-image: -o-linear-gradient(#028acc, #b9ddee);
  background-image: linear-gradient(#028acc, #b9ddee);
  padding: 0.2rem;
}
.dizhi_div {
  padding-top: 0.3rem;
  border-radius: 0.1rem;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: #00234e;
}
.middleInfo {
  width: 6rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.shouhuoren {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.3rem;
}
.picker {
  max-width: 4rem;
  line-height: 1.5;
  margin-right: 0.7rem;
  font-weight: 900;
}
.phone {
  max-width: 2.5rem;
  line-height: 1.5;
}
.shouhuodizhi {
  margin-top: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.detailAddress {
  width: 5rem;
  font-size: 0.3rem;
  line-height: 1.5;
}
.rightIcon {
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.shopping {
  width: 99%;
  background: white;
  padding: 0.1rem;
  display: flex;
  height: auto;
  margin: 0.06rem auto;
  border-radius: 0.2rem;
}
.a {
  flex-basis: 41%;
  height: 2rem;
  border: 0.01rem solid rgb(204, 204, 204);
  border-radius: 0.14rem;
  margin-top: 0.2rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.imghhh {
  width: 100%;
  height: auto;
}
.shopping:nth-child(2) {
  font-size: 0.3rem;
}
.zuoyou {
  display: flex;
  font-size: 0.3rem;
  align-content: center;
  justify-content: space-between;
}
.bn {
  margin: 0.2rem 0 0.2rem 0;
}
#submission {
  height: 100%;
  background: #f5f5f9;
}
.fuyan {
  width: 100%;
  margin: 0.1rem auto;
  height: 0.8rem;
  /* padding-top: .2rem; */
  /* align-items: center; */
  background: white;
  display: flex;
  align-content: center;
  font-size: 0.3rem;
  align-items: center;
}
.fuyan input {
  border: none;
  width: 70%;
}
.ag {
  padding: 0 0.3rem 0 0.3rem;
}
.sp {
  width: 100%;
  height: auto;
  background: white;
}
.am-list-item {
  width: 100%;
  height: auto;
  line-height: 0.7rem;
  padding: 0.3rem;
  background: white;
}
.line {
  display: flex;
  align-content: center;
  font-size: 0.3rem;
  justify-content: space-between;
}
</style>